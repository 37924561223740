import * as React from 'react';
import { SVGProps, memo } from 'react';
const SolFileNewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={66} height={79} fill="none" {...props}>
    <path
      fill="#37373D"
      d="M45.81 16.65a6.665 6.665 0 0 1-6.659-6.658V.75H9.398a8.69 8.69 0 0 0-8.69 8.686V61.82a8.69 8.69 0 0 0 8.69 8.68h35.714a8.688 8.688 0 0 0 8.693-8.69V16.65H45.81Z"
      opacity={0.3}
    />
    <path
      fill="#A7A7A7"
      d="M53.804 16.65H45.81a6.665 6.665 0 0 1-6.659-6.658V.75l14.654 15.9Z"
    />
    <path
      fill="#18CDCA"
      d="M60.105 55.84H19.728a5.186 5.186 0 0 0-5.186 5.186v12.038a5.186 5.186 0 0 0 5.186 5.186h40.377a5.186 5.186 0 0 0 5.186-5.186V61.026a5.186 5.186 0 0 0-5.186-5.186Z"
    />
    <path
      fill="#fff"
      d="M23.938 73.157a1.154 1.154 0 1 0-.016-2.31 1.154 1.154 0 0 0 .015 2.31Zm4.234-3.472-1.857.355c.323 2.002 1.938 3.149 4.456 3.149 2.664 0 4.263-1.211 4.263-3.213 0-1.76-1.179-2.793-3.972-3.504-1.84-.484-2.551-.985-2.551-1.824 0-.904.807-1.421 2.163-1.421 1.292 0 2.002.468 2.277 1.502l1.84-.469c-.435-1.84-1.824-2.76-4.116-2.76-2.568 0-4.037 1.146-4.037 3.148 0 1.743 1.066 2.696 3.649 3.294 1.954.468 2.858 1.097 2.858 1.985 0 .92-.969 1.534-2.438 1.534-1.453 0-2.293-.597-2.535-1.776Zm13.477 3.504c3.245 0 5.538-2.487 5.538-5.845 0-3.358-2.293-5.845-5.538-5.845-3.262 0-5.554 2.487-5.554 5.845 0 3.358 2.292 5.845 5.554 5.845Zm0-1.89c-2.18 0-3.665-1.646-3.665-3.955s1.485-3.956 3.665-3.956c2.18 0 3.649 1.647 3.649 3.956s-1.47 3.956-3.65 3.956Zm14.59-.032h-5.295v-9.574h-1.89v11.302h7.186v-1.728Z"
    />
    <path
      fill="#A7A7A7"
      d="M14.155 37.624a.623.623 0 0 1 .122-.388 1.47 1.47 0 0 1 .349-.323c.201-.105.41-.195.623-.271l.623-.258 2.493-1.05c.347-.161.993-.43 1.938-.807l1.185-.484.426-.152.074-.032a.139.139 0 0 1 .062 0 .369.369 0 0 1 .152-.033.755.755 0 0 1 .549.197.644.644 0 0 1 .197.459v.82a.769.769 0 0 1-.14.426.733.733 0 0 1-.377.304l-5.093 2.083 5.093 2.082a.876.876 0 0 1 .378.297c.09.12.14.267.139.417v.82a.665.665 0 0 1-.45.624.732.732 0 0 1-.296.045.423.423 0 0 1-.152-.03l-.136-.045-.426-.151-1.185-.488-1.938-.804-2.493-1.05-.623-.258a9.166 9.166 0 0 1-.623-.274.816.816 0 0 1-.471-.685v-.991ZM24.352 43.278a.814.814 0 0 1-.552-.19.575.575 0 0 1-.223-.45c0-.122.365-.968 1.095-2.583l1.065-2.341.227-.488 1.717-3.813.607-1.321.123-.304c.047-.112.103-.22.168-.323a.923.923 0 0 1 .82-.442h.76c.201-.007.4.06.555.19a.574.574 0 0 1 .22.45c-.26.752-.563 1.489-.912 2.205l-.167.365-1.292 2.825-1.718 3.814-.607 1.324-.123.303a1.97 1.97 0 0 1-.164.35.991.991 0 0 1-.837.442l-.762-.013ZM32.33 42.405a.75.75 0 0 1-.563-.204.646.646 0 0 1-.197-.465v-.82a.714.714 0 0 1 .13-.416.833.833 0 0 1 .387-.297l5.105-2.083-5.105-2.083a.882.882 0 0 1-.381-.323.76.76 0 0 1-.136-.42v-.833a.618.618 0 0 1 .204-.459.782.782 0 0 1 .555-.197.285.285 0 0 1 .14.033.241.241 0 0 1 .083 0l.068.032c-.023 0 .116.039.41.152l1.201.484c-.062-.028.584.241 1.938.807.738.304 1.584.664 2.538 1.08.061.018.142.05.245.09l.349.138c.204.073.402.164.59.272a.8.8 0 0 1 .472.7v1.001a.645.645 0 0 1-.129.381 1.01 1.01 0 0 1-.342.304c-.18.09-.4.187-.646.29l-.646.259-5.625 2.354c-.162.08-.258.132-.287.152l-.062.045-.297.026Z"
    />
  </svg>
);
const Memo = memo(SolFileNewIcon);
export default Memo;
